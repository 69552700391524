<template>
    <!--应聘-->
    <div>
        
        <el-dialog title="应聘人员" :visible.sync="dlgVisible" v-model="dlgVisible" :close-on-click-modal="false" width="80%">
        <el-row>
            <el-form :inline="true" size="mini" style="padding-left:10px;margin-bottom:-25px">
                <el-form-item label="姓名">
                    <el-input v-model="usernameinput" placeholder="姓名" size="mini"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="mini" plain @click="postsearch">查询</el-button>
                </el-form-item>
            </el-form> 
        </el-row>
        <!--列表-->
        <div class="calc-height">
            <el-table :data="users" highlight-current-row height="100%"  @current-change="selectCurrentRow" class="new_table">
                <el-table-column prop="Name" label="姓名"> </el-table-column>
                <el-table-column prop="Sex" label="性别"></el-table-column>
                <el-table-column prop="MobilePhone" label="联系电话"></el-table-column>
                <el-table-column prop="age" label="年龄"> </el-table-column>
                <el-table-column prop="Diploma" label="学历"></el-table-column>
                <el-table-column prop="ProfessionType" label="专业"> </el-table-column>
                <el-table-column prop="workingTime" label="工作年限"> </el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <el-pagination align="center" @current-change="handleCurrentChange"  :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper,total" :page-count="total" :total="totaldata">
        </el-pagination>

        <div slot="footer" style="text-align: center;">
            <el-button type="primary" size="mini" plain @click="addNewSubmit">提交</el-button>
        </div>

        </el-dialog>
    </div>
</template>

<script>
    import util from "../../../util/date";
    import * as validate from "../../../util/validate";
    import UploadFiles from "../../components/UploadFiles";
    import UserChoose from "../../components/UserChoose";
    import VueQr from 'vue-qr'
    import {
        
    } from "../../api/hr";
    export default {
        components: { UploadFiles, UserChoose, VueQr },
        data() {
            return {
                logoSrc: require('@/assets/grad.jpg'),
                usernameinput: '',
                exportLink: '',
                downloadFilename: '',
                qrcodeKey : 1,
                users: [],
                currentPage: 1, // 当前页码
                pageSize: 20, // 每页的数据条数
                total: null, // 总条数
                totaldata: null,
                optType: false,   // 编辑/查看
                operation: false, // true:新增, false:编辑
                dlgVisible: false, // dlg 显示隐藏
                addUserVisible: false,
                addLecturerVisible: false,
                selUser: {},
            };
        },
        computed: {
            
        },
        methods: {
            // 选中一行显示
            onShowDlg(pid) {
                //this.qrcodeKey = Math.floor(Math.random() * 100);
                this.codeUrl = "";
                if (pid == "" || pid == null) {
                    this.dlgFrom = {
                        ApplyFactory: "",
                        ApplyDpt: "",
                        ApplyPost: "",
                        Apply_QRCode: "",
                        TrainTimeStart: "",
                        TrainTimeEnd: "",
                        Lecturers:[]
                    };
                    this.codeUrl = "",
                    this.key1 += 1;
                    this.key2 += 1;
                    this.key3 += 1;
                    var user = JSON.parse(window.localStorage.user);
                    this.dlgFrom.UserId = user.sub;
                    this.dlgFrom.UserName = user.name;
                    this.dlgVisible = true;
                    this.getUsers();
                }
                else {
                    this.dlgVisible = true;
                    this.getUsers();
                    
                }
            },
            postsearch () {
                let para = { name: this.usernameinput };
                    RegisterGetInfo(para).then((res) => {

                    this.total = res.data.response.pageCount;
                    this.totaldata = res.data.response.dataCount;
                    this.users = res.data.response.data;
                    this.loading = false;
                });
            },
            getUsers(isCurrent = true) {
                if (isCurrent) {
                    this.page = 1;
                }
                let para = {
                    page: this.page,
                    size: this.pageSize,
                };
                
                // this.$api.getUserBaseInfoList(para).then((res) => {
                this.$api.RegisterGetInfo(para).then((res) => {
                    console.log(res.data.response.data);
                    this.total = res.data.response.pageCount;
                    this.totaldata = res.data.response.dataCount;
                    this.users = res.data.response.data;
                    this.loading = false;
                });
            },
            addNewSubmit() {//提交
                let row = this.currentRow;
                console.log(row);
                if (!row) {
                    this.$message({
                    message: "请选择要查看的一行数据！",
                    type: "error",
                    });
                    return;
                }

                this.selUser = {
                    linkId:row.Id,//
                    Name : row.Name,//人员姓名
                    recruitmentId: row.Id,//
                    ApplyDate: row.ApplyDate,//应聘时间
                    applyId: row.Id,//应聘Id
                }

                this.$emit("callback", this.selUser); //将值传给父组件
                this.dlgVisible = false;

            },
            formatStartTime: function (row, colume) {
                var date = row[colume.property];
                if (date == undefined) {
                    return "";
                }
                return util.formatDate.format(new Date(date), "yyyy-MM-dd");
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.page = val;
                this.getUsers(false);
            },
            onSubmit(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let para = Object.assign({}, this.dlgFrom);
                            console.log("onSubmit", this.dlgFrom);
                        } 
                    });
                });
            },
            selectCurrentRow(val) {
                this.currentRow = val;
            },
        },
        mounted() {
            this.getUsers();
        },
        watch: {   //监听值变化
            
        }
    };
</script>

<style scoped>
</style>